<template>
  <div class="form-wrapper">
    <main class="form-signin shadow-lg">
      <div class="text-center">
        <a href="/">
          <img src="../assets/img/eeia-logo.png" class="img-fluid" />
        </a>
      </div>

      <h3 class="h3 mb-4 mt-4 text-center">
        <u>Réinitialiser votre mot de passe</u>
      </h3>

      <div class="container-fluid">
        <div class="row justify-content-center">
          <VeeForm
            class="col-sm-8"
            v-slot="{ handleSubmit }"
            :validation-schema="schema"
            as="div"
          >
            <div class="alert alert-danger d-none" id="alertError" role="alert"></div>
            <form @submit="handleSubmit($event, onSubmit)">
              <div class="mb-3">
                <label for="emailInput" class="form-label">Email</label>
                <Field
                  name="email"
                  type="email"
                  v-model="email"
                  placeholder="Entrer votre email ici."
                  v-slot="{ field, errorMessage }"
                >
                  <input
                    id="emailInput"
                    v-bind="field"
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                  />
                </Field>
                <ErrorMessage name="email" as="div" class="invalid-feedback" />
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Mot de passe</label>
                <Field
                  name="password"
                  type="password"
                  label="mot de passe"
                  v-slot="{ field, errorMessage }"
                >
                  <input
                    id="password"
                    type="password"
                    v-bind="field"
                    placeholder="Entrer votre mot de passe ici."
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                  />
                </Field>
                <ErrorMessage name="password" as="div" class="invalid-feedback" />
              </div>
              <div class="mb-3">
                <label for="confirmedPassword" class="form-label"
                  >Confirmer votre mot de passe</label
                >
                <Field
                  name="confirmedPassword"
                  type="password"
                  label="confirmer mot de passe"
                  v-slot="{ field, errorMessage }"
                >
                  <input
                    id="confirmedPassword"
                    type="password"
                    v-bind="field"
                    placeholder="Re-saisissez le mot de passe."
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                  />
                </Field>
                <ErrorMessage
                  name="confirmedPassword"
                  as="div"
                  class="invalid-feedback"
                />
              </div>

              <input type="hidden" name="token" v-model="token" />

              <button class="w-100 btn btn-lg btn-primary" type="submit">
                Réinitialiser le mot de passe
              </button>
            </form>
          </VeeForm>
        </div>
      </div>

      <div class="text-center fs-6 mt-5">
        <img src="../assets/img/logo_be_vallet_pnud.svg" height="100" alt=""  />
        <p class="mb-1 text-muted">&copy; Bénin Excellence 2021–<span id="currentYear">2022</span></p>
        <div class="mb-3 text-muted">
          <ul class="list-inline">
            <li class="list-inline-item">
              <a href="#" @click="reconnect()">Retourner sur la page de connexion</a>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, email, min, confirmed } from "@vee-validate/rules";

defineRule("required", required);
defineRule("email", email);
defineRule("min", min);
defineRule("confirmed", confirmed);

import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

export default {
  name: "PasswordForget",
  inject: ["ToastSuccess", "ToastError", "currentYearUrl"],
  components: {
    // Rename form to avoid confusion with native `form`
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    msg: String,
  },
  mounted() {
    document.querySelector("body").style.backgroundColor = "#446ead";
    let url = window.location.search;
    let url_params = new URLSearchParams(url);
    if (url_params.has("email") && url_params.has("token")) {
      this.email = url_params.get("email");
      this.token = url_params.get("token");
    }
    localStorage.setItem("session_expired", "false");
    fetch(this.currentYearUrl)
      .then((result) => {
        result.text().then(function (text) {
          document.getElementById("currentYear").textContent = text;
        });
      })
      .catch(() => {
        document.getElementById("currentYear").textContent = "2022";
      });
  },
  data() {
    const schema = {
      email: "required|email",
      confirmedPassword: "required|confirmed:@password",
    };
    return {
      schema,
      token: "",
      email: "",
    };
  },
  methods: {
    onSubmit(values) {
      this.$http({
        url: "reset-password",
        method: "post",
        data: {
          email: values.email,
          token: this.token,
          password: values.password,
          password_confirmation: values.confirmedPassword,
        },
      })
        .then(() => {
          this.ToastSuccess.fire({
            title: "Votre mot de passe a été réinitialisé avec succès!",
          });
          this.$router.push("login");
        })
        .catch(() => {
          this.ToastError.fire({
            title: "Une erreur s'est produite, veuillez réessayer.",
          });
        });
    },
    reconnect() {
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      this.$router.push("login");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  /* background-image: var(--bs-gradient)!important; */
  background-color: #446ead !important;
  font-size: 20px;
}

@media (min-width: 768px) {
  .form-control {
    line-height: 2.5;
  }
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1.5rem !important;
  font-weight: bold !important;
}

.form-wrapper {
  width: 100%;
  padding: 15px;
  max-width: 800px;
  margin: auto;
  margin-top: 11vh;
  margin-bottom: 8vh;
}

@media (max-width: 575.98px) {
  .form-wrapper {
    margin-top: 7.5vh !important;
    margin-bottom: 4vh !important;
  }

  .form-signin {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.form-signin {
  padding: 15px;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  background: white;
  border-radius: 8px;
}

ul.resend {
  padding-left: 3rem;
}

.img-wrapper {
  width: 130px;
  margin: auto;
}
</style>
