<template>
  <div class="row">
    <VeeForm
      class="col"
      v-slot="{ handleSubmit, meta }"
      :validation-schema="schema"
      as="div"
      :initial-values="formValues"
    >
      <div class="alert alert-danger d-none" id="alertError" role="alert"></div>
      <form @submit="handleSubmit($event, onSubmit)" class="row">
        <div class="col">
          <div class="row mb-2">
            <div class="col mt-5">
              <h2 class="section-title text-uppercase">Social</h2>
            </div>
          </div>

          <div class="row">
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="computer" class="form-label required"
                >Avez-vous un ordinateur portable ?</label
              >
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="computer"
                  id="computer1"
                  value="Oui"
                  v-model="computer"
                />
                <label class="form-check-label" for="computer1"> Oui </label>
              </div>
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="computer"
                  id="computer2"
                  value="Non"
                  v-model="computer"
                />
                <label class="form-check-label" for="computer2"> Non </label>
              </div>
              <ErrorMessage name="computer" as="div" class="invalid-feedback" />
            </div>

            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="computer == 'Oui'"
            >
              <label for="year" class="form-label"
                >En quelle année l'avez-vous pris ?</label
              ><label class="required"></label>
              <Field
                name="year"
                v-model="year"
                type="text"
                label="ci-dessous"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="year"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage name="year" as="div" class="invalid-feedback" />
            </div>

            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="computer == 'Oui'"
            >
              <label for="workingComputer" class="form-label"
                >Est-il en bon état de marche ?</label
              ><label class="required"></label>
              <Field
                name="workingComputer"
                v-model="workingComputer"
                type="text"
                label="ci-dessous"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="workingComputer"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="workingComputer"
                as="div"
                class="invalid-feedback"
              />
            </div>

            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="computer == 'Oui'"
            >
              <label for="computerDescription" class="form-label"
                >Décrivez, si vous le pouvez, votre ordinateur portable.</label
              >
              <Field
                name="computerDescription"
                v-model="computerDescription"
                type="text"
                label="ci-dessous"
                v-slot="{ field, errorMessage }"
              >
                <textarea
                  id="computerDescription"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                  cols="30"
                  rows="2"
                ></textarea>
              </Field>
              <ErrorMessage
                name="computerDescription"
                as="div"
                class="invalid-feedback"
              />
            </div>
          </div>

          <div class="row" v-if="nativeCountry == 'Bénin'">
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="residence" class="form-label required"
                >En cas d'admission, voulez-vous être hébergé à l'internat au
                cours de cette école ?</label
              >
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="residence"
                  id="residence1"
                  value="Oui"
                  v-model="residence"
                />
                <label class="form-check-label" for="residence1"> Oui </label>
              </div>
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="residence"
                  id="residence2"
                  value="Non"
                  v-model="residence"
                />
                <label class="form-check-label" for="residence2"> Non </label>
              </div>
              <ErrorMessage
                name="residence"
                as="div"
                class="invalid-feedback"
              />
            </div>
          </div>

          <div
            class="row"
            v-if="residence == 'Oui' && nativeCountry == 'Bénin'"
          >
            <div class="col">
              <!-- //* CRITÈRES SOCIAUX -->
              <div class="row mb-2">
                <div class="col mt-2">
                  <h2 class="section-title">
                    Critères sociaux d’admission a l’internat
                  </h2>
                </div>
              </div>

              <div class="alert alert-info" role="alert">
                Choisissez une réponse par critère dans le formulaire
                ci-dessous.
              </div>

              <div class="row mb-3" v-if="!hasAlreadySubmited">
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title h4">
                        1. Situation socioprofessionnelle des parents et ou
                        tuteurs du candidat
                      </h5>
                      <div class="mb-4">
                        <Field name="parentSituation" label="ci-dessous">
                          <social-radio
                            name="parentSituation"
                            value="situation_1"
                            label="Les deux parents et ou tuteurs sont au chômage ou sans activité régulière"
                            :isChecked="parentSituation == 'situation_1'"
                          ></social-radio>
                          <social-radio
                            name="parentSituation"
                            value="situation_2"
                            label="Le papa ou le tuteur principal est au chômage ou sans activité régulière"
                            :isChecked="parentSituation == 'situation_2'"
                          ></social-radio>
                          <social-radio
                            name="parentSituation"
                            value="situation_3"
                            label="La maman ou le tuteur secondaire est au chômage ou sans activité"
                            :isChecked="parentSituation == 'situation_3'"
                          ></social-radio>
                          <social-radio
                            name="parentSituation"
                            value="situation_4"
                            label="Aucun des parents et ou tuteurs n’est au chômage ou sans activité"
                            :isChecked="parentSituation == 'situation_4'"
                          ></social-radio>
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3" v-if="!hasAlreadySubmited">
                <div class="col">
                  <div class="card striped-card">
                    <div class="card-body">
                      <h5 class="card-title h4">
                        2. Situation familiale du candidat
                      </h5>
                      <div class="mb-4">
                        <Field name="familySituation" label="ci-dessous">
                          <social-radio
                            name="familySituation"
                            value="situation_1"
                            label="Famille nucléaire"
                            :isChecked="familySituation == 'situation_1'"
                          ></social-radio>
                          <social-radio
                            name="familySituation"
                            value="situation_2"
                            label="Famille monoparentale avec un homme comme chef de famille"
                            :isChecked="familySituation == 'situation_2'"
                          ></social-radio>
                          <social-radio
                            name="familySituation"
                            value="situation_3"
                            label="Famille monoparentale avec une femme comme cheffe de famille"
                            :isChecked="familySituation == 'situation_3'"
                          ></social-radio>
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3" v-if="!hasAlreadySubmited">
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title h4">
                        3. Condition physique du bénéficiaire
                      </h5>
                      <div class="mb-4">
                        <Field name="physicalCondition" label="ci-dessous">
                          <social-radio
                            name="physicalCondition"
                            value="situation_1"
                            label="En situation de handicap"
                            :isChecked="physicalCondition == 'situation_1'"
                          ></social-radio>
                          <social-radio
                            name="physicalCondition"
                            value="situation_2"
                            label="Exempt de tout handicap"
                            :isChecked="physicalCondition == 'situation_2'"
                          ></social-radio>
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3" v-if="!hasAlreadySubmited">
                <div class="col">
                  <div class="card striped-card">
                    <div class="card-body">
                      <h5 class="card-title h4">
                        4. Situation socio-économique du ménage dans lequel vit
                        le bénéficiaire
                      </h5>
                      <div class="mb-4">
                        <Field name="familyMeal" label="ci-dessous">
                          <social-radio
                            name="familyMeal"
                            value="situation_1"
                            label="Les membres du ménage prennent au moins trois repas par jour"
                            :isChecked="familyMeal == 'situation_1'"
                          ></social-radio>
                          <social-radio
                            name="familyMeal"
                            value="situation_2"
                            label="Les membres du ménage prennent deux repas par jour"
                            :isChecked="familyMeal == 'situation_2'"
                          ></social-radio>
                          <social-radio
                            name="familyMeal"
                            value="situation_3"
                            label="Les membres du ménage prennent moins de deux repas par jour"
                            :isChecked="familyMeal == 'situation_3'"
                          ></social-radio>
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3" v-if="!hasAlreadySubmited">
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title h4">
                        5. Zone de résidence du bénéficiaire
                      </h5>
                      <div class="row">
                        <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <label for="city" class="form-label required"
                            >Ville</label
                          >
                          <Field
                            name="city"
                            v-model="city"
                            type="text"
                            label="ci-dessous"
                            v-slot="{ field, errorMessage }"
                          >
                            <input
                              id="city"
                              v-bind="field"
                              placeholder="Saisissez un texte"
                              class="form-control"
                              :class="{ 'is-invalid': errorMessage }"
                            />
                          </Field>
                          <ErrorMessage
                            name="city"
                            as="div"
                            class="invalid-feedback"
                          />
                        </div>
                        <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <label for="neighborhood" class="form-label required"
                            >Quartier</label
                          >
                          <Field
                            name="neighborhood"
                            v-model="neighborhood"
                            type="text"
                            label="ci-dessous"
                            v-slot="{ field, errorMessage }"
                          >
                            <input
                              id="neighborhood"
                              v-bind="field"
                              placeholder="Saisissez un texte"
                              class="form-control"
                              :class="{ 'is-invalid': errorMessage }"
                            />
                          </Field>
                          <ErrorMessage
                            name="neighborhood"
                            as="div"
                            class="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3" v-if="!hasAlreadySubmited">
                <div class="col">
                  <div class="card striped-card">
                    <div class="card-body">
                      <h5 class="card-title h4">
                        6. Moyens de déplacement du bénéficiaire
                      </h5>
                      <div class="mb-4">
                        <Field name="travel" label="catégorie 3">
                          <social-radio
                            name="travel"
                            value="situation_1"
                            label="Moto"
                            :isChecked="travel == 'situation_1'"
                          ></social-radio>
                          <social-radio
                            name="travel"
                            value="situation_2"
                            label="Aucun moyen de transport"
                            :isChecked="travel == 'situation_2'"
                          ></social-radio>
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3" v-if="!hasAlreadySubmited">
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title h4">
                        7. Argent de poche du bénéficiaire
                      </h5>
                      <div class="mb-4">
                        <Field name="pocketMoney" label="catégorie 2">
                          <social-radio
                            name="pocketMoney"
                            value="situation_1"
                            label="Pas d’argent de poche"
                            :isChecked="pocketMoney == 'situation_1'"
                          ></social-radio>
                          <social-radio
                            name="pocketMoney"
                            value="situation_2"
                            label="Un argent de poche inférieur ou égale à 1000 FCFA par semaine"
                            :isChecked="pocketMoney == 'situation_2'"
                          ></social-radio>
                          <social-radio
                            name="pocketMoney"
                            value="situation_3"
                            label="Un argent de poche entre 1000 et 1500 FCFA par semaine"
                            :isChecked="pocketMoney == 'situation_3'"
                          ></social-radio>
                          <social-radio
                            name="pocketMoney"
                            value="situation_4"
                            label="Un argent de poche supérieur à 1500 FCFA par semaine"
                            :isChecked="pocketMoney == 'situation_4'"
                          ></social-radio>
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2">
            <button
              class="btn btn-lg btn-primary"
              @click="$emit('to-previous')"
              type="button"
            >
              Précédent
            </button>
            <button
              class="btn btn-lg btn-primary ms-2"
              id="submit-button"
              type="submit"
            >
              Suivant
            </button>
          </div>
          <div class="row mb-4">
            <span class="text-danger d-none" id="form-invalid-error"
              ><i v-if="!meta.valid">
                Une ou plusieurs erreurs sont présentes dans le formulaire.</i
              ></span
            >
            <div class="mt-3 fst-italic">
              <span class="text-muted">
                <label class="required"></label> Obligatoire
              </span>
            </div>
          </div>
        </div>
      </form>
    </VeeForm>
  </div>
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";
import SocialRadio from "../components/SocialRadio.vue";

defineRule("required", required);
defineRule("required_if_computer", (value, [inputField]) => {
  if (inputField == "Oui") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});

defineRule("required_if_residence", (value, [inputField]) => {
  if (inputField == "Oui") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});

import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

import { Tooltip } from "bootstrap";

export default {
  name: "Social",
  props: {
    previousData: Object,
    step: Number,
    nativeCountry: String,
  },
  inject: ["ToastSuccess", "ToastError", "currentYearUrl", "AlertSuccess"],
  components: {
    VeeForm,
    Field,
    ErrorMessage,
    SocialRadio,
  },
  mounted() {
    if (this.nativeCountry != "Bénin") {
      this.residence = "Non";
    }
    // localStorage.setItem("currentStep", 2);
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
    document
      .getElementById("submit-button")
      .addEventListener("click", function () {
        document
          .getElementById("form-invalid-error")
          .classList.remove("d-none");
      });
  },
  data() {
    let schema = {
      computer: "required",
      year: "required_if_computer:@computer",
      workingComputer: "required_if_computer:@computer",
      residence: "required",
      parentSituation: "required_if_residence:@residence",
      familySituation: "required_if_residence:@residence",
      physicalCondition: "required_if_residence:@residence",
      familyMeal: "required_if_residence:@residence",
      city: "required_if_residence:@residence",
      neighborhood: "required_if_residence:@residence",
      travel: "required_if_residence:@residence",
      pocketMoney: "required_if_residence:@residence",
    };

    const formValues = this.previousData;

    return {
      schema,
      computer: this.previousData.computer,
      year: this.previousData.year,
      workingComputer: this.previousData.workingComputer,
      computerDescription: this.previousData.computerDescription,
      residence: this.previousData.residence,
      parentSituation: this.previousData.parentSituation,
      familySituation: this.previousData.familySituation,
      physicalCondition: this.previousData.physicalCondition,
      familyMeal: this.previousData.familyMeal,
      city: this.previousData.city,
      neighborhood: this.previousData.neighborhood,
      travel: this.previousData.travel,
      pocketMoney: this.previousData.pocketMoney,
      formValues: formValues,
    };
  },
  methods: {
    onSubmit(values, actions) {
      this.beforeSendPromise(values, this).then((formData) => {
        this.$http
          .post("new-candidacies", formData)

          .then(() => {
            this.$emit("to-next");
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.ToastError.fire({
                title: "Veuillez bien remplir le formulaire et réessayez.",
              });

              let errors = error.response.data.errors;
              for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                  const element = errors[key];
                  actions.setFieldError(key, element);
                }
              }
            } else if (error.response.status == 418) {
              this.AlertError.fire({
                icon: "error",
                title: "Délai dépassé !",
                html: "Vous ne pouvez plus soumettre votre dossier.",
              });
            } else {
              this.ToastError.fire({
                title: "Une erreur s'est produite, veuillez réessayer.",
              });
            }
          });
      });
    },
    beforeSendPromise(values, $this) {
      return new Promise(function (resolve) {
        let formData = new FormData();
        formData.append("step", 4);
        formData.append("computer", $this.computer);

        if ($this.year == null) {
          formData.append("year", "");
        } else {
          formData.append("year", $this.year);
        }

        if ($this.workingComputer == null) {
          formData.append("workingComputer", "");
        } else {
          formData.append("workingComputer", $this.workingComputer);
        }

        if ($this.computerDescription == null) {
          formData.append("computerDescription", "");
        } else {
          formData.append("computerDescription", $this.computerDescription);
        }

        formData.append("residence", $this.residence);

        if (values.parentSituation == null) {
          formData.append("parentSituation", "");
        } else {
          formData.append("parentSituation", values.parentSituation);
        }

        if (values.familySituation == null) {
          formData.append("familySituation", "");
        } else {
          formData.append("familySituation", values.familySituation);
        }

        if (values.physicalCondition == null) {
          formData.append("physicalCondition", "");
        } else {
          formData.append("physicalCondition", values.physicalCondition);
        }

        if (values.familyMeal == null) {
          formData.append("familyMeal", "");
        } else {
          formData.append("familyMeal", values.familyMeal);
        }

        if (values.travel == null) {
          formData.append("travel", "");
        } else {
          formData.append("travel", values.travel);
        }

        if (values.pocketMoney == null) {
          formData.append("pocketMoney", "");
        } else {
          formData.append("pocketMoney", values.pocketMoney);
        }

        if ($this.neighborhood == null) {
          formData.append("neighborhood", "");
        } else {
          formData.append("neighborhood", $this.neighborhood);
        }

        if ($this.city == null) {
          formData.append("city", "");
        } else {
          formData.append("city", $this.city);
        }


        resolve(formData);
      });
    },
  },
  computed: {
    hasAlreadySubmited() {
      let stepp = 2;
      if (stepp >= 3) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.border-right-separator {
  border-right: 1px solid #80808047;
}

.striped-card {
  background-color: #7fbcd921;
}

.section-title {
  border-bottom: 3px solid #3490dc;
}

/* ============ mobile view ============ */
@media all and (max-width: 991px) {
  .border-right-separator {
    border-right: unset;
  }
}
/* ============ mobile view .end// ============ */

/** #7fbcd9 */
</style>
