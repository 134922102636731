<template>
  <div class="row">
    <VeeForm
      class="col"
      v-slot="{ handleSubmit, meta }"
      :validation-schema="schema"
      as="div"
    >
      <div class="alert alert-danger d-none" id="alertError" role="alert"></div>
      <form
        @submit="handleSubmit($event, onSubmit)"
        class="row"
        id="generalityForm"
        ref="generalityForm"
      >
        <div class="col">
          <!-- //* Motivation -->
          <!-- <div class="alert alert-danger fs-5 mt-5 text-center" role="alert">
            Les candidatures sont closes.
          </div> -->
          <div class="row mb-2 mt-5">
            <div class="col mt-1">
              <h2 class="section-title text-uppercase">Motivations</h2>
            </div>
          </div>

          <!-- //* Attente eeia -->
          <div class="row">
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="expectations" class="form-label required"
                >Qu'attendez-vous de cette école d'été ?</label
              >
              <Field
                name="expectations"
                v-model="expectations"
                type="text"
                label="ci-dessous"
                v-slot="{ field, errorMessage }"
              >
                <textarea
                  id="expectations"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                  cols="30"
                  rows="2"
                ></textarea>
              </Field>
              <ErrorMessage
                name="expectations"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="appliedOnce" class="form-label required"
                >Avez-vous déjà postulé au moins une fois ?</label
              >
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="appliedOnce"
                  id="appliedOnce1"
                  value="Oui"
                  v-model="appliedOnce"
                />
                <label class="form-check-label" for="appliedOnce1"> Oui </label>
              </div>
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="appliedOnce"
                  id="appliedOnce2"
                  value="Non"
                  v-model="appliedOnce"
                />
                <label class="form-check-label" for="appliedOnce2"> Non </label>
              </div>
              <ErrorMessage
                name="appliedOnce"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="availablity" class="form-label required"
                >Êtes-vous disponible entre le 17 juillet et le 12 août 2023
                ?</label
              >
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="availablity"
                  id="availablity1"
                  value="Oui"
                  v-model="availablity"
                />
                <label class="form-check-label" for="availablity1">
                  Oui, je suis disponible
                </label>
              </div>
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="availablity"
                  id="availablity2"
                  value="Non"
                  v-model="availablity"
                />
                <label class="form-check-label" for="availablity2">
                  Non, je ne suis pas disponible
                </label>
              </div>
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="availablity"
                  id="availablity3"
                  value="PasEncore"
                  v-model="availablity"
                />
                <label class="form-check-label" for="availablity3">
                  Je ne sais pas encore
                </label>
              </div>
              <ErrorMessage
                name="availablity"
                as="div"
                class="invalid-feedback"
              />
            </div>
          </div>

          <div class="mt-2">
            <button
              class="btn btn-lg btn-primary"
              @click="$emit('to-previous')"
              type="button"
            >
              Précédent
            </button>
            <button
              class="btn btn-lg btn-primary ms-2"
              id="submit-button"
              type="submit"
            >
              Suivant
            </button>
          </div>

          <div class="row mb-4">
            <span class="text-danger d-none" id="form-invalid-error"
              ><i v-if="!meta.valid">
                Une ou plusieurs erreurs sont présentes dans le formulaire.</i
              ></span
            >
            <div class="mt-3 fst-italic">
              <span class="text-muted">
                <label class="required"></label> Obligatoire
              </span>
            </div>
          </div>
        </div>
      </form>
    </VeeForm>
  </div>
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";

import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

defineRule("required", required);

export default {
  name: "EeiaMotivation",
  inject: [
    "ToastSuccess",
    "ToastError",
    "currentYearUrl",
    "AlertWarning",
    "AlertSuccess",
    "AlertError",
  ],
  components: {
    // Rename form to avoid confusion with native `form`
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    previousData: Object,
    step: Number,
  },
  mounted() {
    document
      .getElementById("submit-button")
      .addEventListener("click", function () {
        document
          .getElementById("form-invalid-error")
          .classList.remove("d-none");
      });
  },
  data() {
    const schema = {
      expectations: "required",
      appliedOnce: "required",
      availablity: "required",
    };

    return {
      schema,
      expectations: this.previousData.expectations,
      appliedOnce: this.previousData.appliedOnce,
      availablity: this.previousData.availablity,
    };
  },
  methods: {
    onSubmit(values, actions) {
      this.beforeSendPromise(values, this).then((formData) => {
        this.$http
          .post("new-candidacies", formData)

          .then(() => {
            this.$emit("to-next");
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.ToastError.fire({
                title: "Veuillez bien remplir le formulaire et réessayez.",
              });

              let errors = error.response.data.errors;
              for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                  const element = errors[key];
                  actions.setFieldError(key, element);
                }
              }
            } else if (error.response.status == 403) {
              this.AlertError.fire({
                icon: "error",
                title: "Non autorisé",
                html: "Vous ne pouvez plus modifier votre candidature.",
              });
            } else {
              this.ToastError.fire({
                title: "Une erreur s'est produite, veuillez réessayer.",
              });
            }
          });
      });
    },
    beforeSendPromise(values, $this) {
      return new Promise(function (resolve) {
        values.step = 3;
        let formData = new FormData();
        formData.append("step", 3);

        formData.append("expectations", $this.expectations);
        formData.append("appliedOnce", $this.appliedOnce);
        formData.append("availablity", $this.availablity);

        resolve(formData);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 768px) {
  .form-control,
  .form-select {
    line-height: 2;
  }
}

.section-title {
  border-bottom: 3px solid #3490dc;
}
</style>
