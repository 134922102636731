<template>
  <div class="container px-sm-0 bg-light">
    <div class="row adjust-margin">
      <div class="col">
        <div class="container px-sm-5">
          <div class="row">
            <VeeForm
              class="col"
              v-slot="{ handleSubmit }"
              :validation-schema="infoSchema"
              as="div"
            >
              <div
                class="alert alert-danger d-none"
                id="alertError"
                role="alert"
              ></div>
              <form
                @submit="handleSubmit($event, onPersonalDataSubmit)"
                class="row"
                id="generalityForm"
                ref="generalityForm"
              >
                <div class="col">
                  <!-- //* Généralité -->

                  <div class="row mb-2">
                    <div class="col mt-5">
                      <h2 class="section-title text-uppercase">Informations personnelles</h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <label for="last_name" class="form-label">Nom</label>
                      <Field
                        name="last_name"
                        v-model="last_name"
                        type="text"
                        label="nom"
                        v-slot="{ field, errorMessage }"
                      >
                        <input
                          id="last_name"
                          v-bind="field"
                          class="form-control"
                          :class="{ 'is-invalid': errorMessage }"
                        />
                      </Field>
                      <ErrorMessage
                        name="last_name"
                        as="div"
                        class="invalid-feedback"
                      />
                    </div>
                    <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <label for="first_name" class="form-label">Prénom</label>
                      <Field
                        name="first_name"
                        type="text"
                        v-model="first_name"
                        label="prénom"
                        v-slot="{ field, errorMessage }"
                      >
                        <input
                          id="first_name"
                          v-bind="field"
                          class="form-control"
                          :class="{ 'is-invalid': errorMessage }"
                        />
                      </Field>
                      <ErrorMessage
                        name="first_name"
                        as="div"
                        class="invalid-feedback"
                      />
                    </div>
                    <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <label for="emailInput" class="form-label">Email</label>
                      <Field
                        name="email"
                        v-model="email"
                        type="email"
                        v-slot="{ field, errorMessage }"
                      >
                        <input
                          id="emailInput"
                          v-bind="field"
                          class="form-control"
                          :class="{ 'is-invalid': errorMessage }"
                          disabled
                        />
                      </Field>
                      <ErrorMessage
                        name="email"
                        as="div"
                        class="invalid-feedback"
                      />
                    </div>
                    <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <label for="phone" class="form-label">Téléphone</label>
                      <Field
                        name="phone"
                        v-model="phone"
                        type="text"
                        label="téléphone"
                        v-slot="{ field, errorMessage }"
                      >
                        <input
                          id="phone"
                          v-bind="field"
                          class="form-control"
                          :class="{ 'is-invalid': errorMessage }"
                        />
                        <ErrorMessage
                          name="phone"
                          as="div"
                          class="invalid-feedback"
                          :class="{ 'd-block': errorMessage }"
                        />
                      </Field>
                    </div>
                    <div class="mb-4 row-col">
                      <button class="btn btn-primary" type="submit">
                        Modifier mon profil
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </VeeForm>
          </div>
          <div class="row">
            <VeeForm
              class="col"
              v-slot="{ handleSubmit }"
              :validation-schema="passwordSchema"
              as="div"
            >
              <div
                class="alert alert-danger d-none"
                id="alertError"
                role="alert"
              ></div>
              <form
                @submit="handleSubmit($event, onPasswordSubmit)"
                class="row"
                id="generalityForm2"
                ref="generalityForm2"
              >
                <div class="col">
                  <!-- //* Généralité -->

                  <div class="row mb-2">
                    <div class="col mt-5">
                      <h2 class="section-title text-uppercase">Modifier le mot de passe</h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <label for="currentPassword" class="form-label"
                        >Mot de passe actuel</label
                      >
                      <Field
                        name="currentPassword"
                        v-model="currentPassword"
                        label="mot de passe"
                        v-slot="{ field, errorMessage }"
                      >
                        <input
                          id="currentPassword"
                          type="password"
                          v-bind="field"
                          placeholder="Entrer votre mot de passe ici."
                          class="form-control"
                          :class="{ 'is-invalid': errorMessage }"
                        />
                      </Field>
                      <ErrorMessage
                        name="currentPassword"
                        as="div"
                        class="invalid-feedback"
                      />
                    </div>
                    <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <label for="newPassword" class="form-label"
                        >Nouveau mot de passe</label
                      >
                      <Field
                        name="newPassword"
                        v-model="newPassword"
                        type="password"
                        label="mot de passe"
                        v-slot="{ field, errorMessage }"
                      >
                        <input
                          id="newPassword"
                          type="password"
                          v-bind="field"
                          placeholder="Entrer votre mot de passe ici."
                          class="form-control"
                          :class="{ 'is-invalid': errorMessage }"
                        />
                      </Field>
                      <ErrorMessage
                        name="newPassword"
                        as="div"
                        class="invalid-feedback"
                      />
                    </div>
                    <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <label for="newPasswordConfirmation" class="form-label"
                        >Confirmer le nouveau mot de passe</label
                      >
                      <Field
                        name="newPasswordConfirmation"
                        v-model="newPasswordConfirmation"
                        label="confirmer mot de passe"
                        v-slot="{ field, errorMessage }"
                      >
                        <input
                          id="newPasswordConfirmation"
                          type="password"
                          v-bind="field"
                          placeholder="Re-saisissez le mot de passe."
                          class="form-control"
                          :class="{ 'is-invalid': errorMessage }"
                        />
                      </Field>
                      <ErrorMessage
                        name="newPasswordConfirmation"
                        as="div"
                        class="invalid-feedback"
                      />
                    </div>
                    <div class="mb-4 col">
                      <button class="btn btn-primary" type="submit">
                        Modifier le mot de passe
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </VeeForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, email, confirmed } from "@vee-validate/rules";
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import parsePhoneNumber from "libphonenumber-js";
var iti;

defineRule("required", required);
defineRule("email", email);
defineRule("confirmed", confirmed);

defineRule("telephone", (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  // Check if international telephone numbers is valid
  if (document.querySelector("#phone") != null) {
    if (document.querySelector("#phone").value.indexOf("+") != -1) {
      return true;
    }
  }
  if (!iti.isValidNumber()) {
    return "Le numéro de téléphone n'est pas valide.";
  }
  return true;
});

import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

export default {
  name: "Profile",
  inject: ["ToastSuccess", "ToastError", "currentYearUrl"],
  components: {
    // Rename form to avoid confusion with native `form`
    VeeForm,
    Field,
    ErrorMessage,
  },
  // props: {
  //   msg: String,
  // },
  mounted() {
    document.querySelector("body").style.backgroundColor = "#446ead";
    let input = document.querySelector("#phone");
    iti = intlTelInput(input, {
      preferredCountries: ["bj", "bf", "ci", "dj", "ne", "sn", "tg"],
      initialCountry: "bj",
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.min.js",
    });
    this.getUserRequest(localStorage.getItem("userId"));
    document.getElementById("alertError").textContent = "";
    document.getElementById("alertError").classList.add("d-none");
    fetch(this.currentYearUrl)
      .then((result) => {
        result.text().then(function (text) {
          document.getElementById("currentYear").textContent = text;
        });
      })
      .catch(() => {
        document.getElementById("currentYear").textContent = "2022";
      });

    // let that = this;
    // const phoneNumber = parsePhoneNumber(this.phone);
    // let appIsAlreadyMounted = false;

    // document.querySelector(".iti").addEventListener("click", function () {
    //   if (!appIsAlreadyMounted) {
    //     appIsAlreadyMounted = !appIsAlreadyMounted;
    //     that.phone = phoneNumber.formatNational();
    //   }
    // });
  },
  data() {
    const infoSchema = {
      first_name: "required",
      last_name: "required",
      phone: "required|telephone",
    };

    const passwordSchema = {
      currentPassword: "required",
      newPassword: "required",
      newPasswordConfirmation: "required|confirmed:@newPassword",
    };

    return {
      infoSchema,
      passwordSchema,
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      userId: localStorage.getItem("userId"),
    };
  },
  methods: {
    onPasswordSubmit() {
      this.$http
        .put("users/" + this.userId, {
          modificationType: "password",
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          newPassword_confirmation: this.newPasswordConfirmation,
        })

        .then(() => {
          this.ToastSuccess.fire({
            title: "Modification effectuée avec succès !",
          });
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.ToastError.fire({
              title: "Veuillez bien remplir le formulaire et réessayez.",
            });
          } else if (error.response.status == 403) {
            this.ToastError.fire({
              title: "L'actuel mot de passe est incorrect.",
            });
          } else {
            this.ToastError.fire({
              title: "Une erreur s'est produite, veuillez réessayer.",
            });
          }
        });
    },
    onPersonalDataSubmit() {
      this.$http
        .put("users/" + this.userId, {
          modificationType: "personal",
          firstName: this.first_name,
          lastName: this.last_name,
          phone: iti.getNumber(),
        })

        .then(() => {
          this.ToastSuccess.fire({
            title: "Modification effectuée avec succès !",
          });
          document.getElementById("navbarLastName").textContent =
            this.last_name;
          document.getElementById("navbarFirstName").textContent =
            this.first_name;
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.ToastError.fire({
              title: "Veuillez bien remplir le formulaire et réessayez.",
            });
          } else if (error.response.status == 403) {
            this.ToastError.fire({
              title: "L'actuel mot de passe est incorrect.",
            });
          } else {
            this.ToastError.fire({
              title: "Une erreur s'est produite, veuillez réessayer.",
            });
          }
        });
    },
    getUserRequest(userId) {
      this.$http
        .get("users/" + userId)

        .then((response) => {
          this.email = response.data.email;
          this.first_name = response.data.first_name;
          this.last_name = response.data.last_name;
          this.phone = response.data.phone;
          this.formatPhoneInputOnFirstInput();
        })
        .catch(() => {
          this.ToastError.fire({
            title: "Une erreur s'est produite, veuillez recharger la page.",
          });
        });
    },
    formatPhoneInputOnFirstInput() {
      let that = this;
      const phoneNumber = parsePhoneNumber(this.phone);
      let appIsAlreadyMounted = false;

      document.querySelector(".iti").addEventListener("click", function () {
        if (!appIsAlreadyMounted) {
          appIsAlreadyMounted = !appIsAlreadyMounted;
          that.phone = phoneNumber.formatNational();
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 768px) {
  .form-control,
  .form-select {
    line-height: 2;
  }
}

.section-title {
  border-bottom: 3px solid #3490dc;
}
</style>
